import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WindowIcon from "@mui/icons-material/Window";
import TabIcon from "@mui/icons-material/Tab";
import DvrIcon from "@mui/icons-material/Dvr";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function AIandML() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="AI and ML"
          subtitle="Leverage AI and ML Solutions to Automate Processes and Make Smart Decisions"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="Key benefits of AI and ML Solutions for Business"
          subtitle={
            <div>
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "orange",
                    marginRight: "15px",
                  }}
                />
                Better Decision-Making
              </div>
              Artificial Intelligence (AI) and Machine Learning (ML) speed up
              data processing and programming, which reduces time and effort in
              making better decisions.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "blue",
                    marginRight: "15px",
                  }}
                />
                Accuracy{" "}
              </div>
              Prevent human errors and ensure accurate results with the help of
              Artificial Intelligence in your processes.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "teal",
                    marginRight: "15px",
                  }}
                />
                Process Optimization
              </div>
              Machine learning can be used to streamline corporate processes and
              help find anomalies in them to boost efficiency.
            </div>
          }
          image={require("../img/webd.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TravelExploreIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  AI and ML Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our world-class AI solutions are created by a team of AI and ML
                specialists to speed up your digital transformation and serve
                across variety of industries.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DvrIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  AI-Powered Applications
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                By utilizing the capabilities provided by AI, we assist you in
                better understanding user behavior and adding a personalized
                touch to your surfing experience.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <WindowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Chatbot Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our chatbot developers provide customer care through human-like
                dialogues to your customers with our chatbot development
                services, reducing time and expenses.
              </div>
            </div>
          }
        />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <BlurOnIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Machine Learning
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our team of machine learning experts assists in developing a
                system that can support you in making important business
                decisions.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TabIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Image Processing
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our image processing services are designed to scale up your
                operations, improve operational environments, and deploy data to
                the cloud.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DoubleArrowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Natural Language Processing
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Utilize NLP to extract meaning from huge data collections.We
                assist you in creating digital assistants
              </div>
            </div>
          }
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
