import * as React from "react";
import { useInView, useSpring, animated } from "@react-spring/web";
import { buildInteractionObserverThreshold } from "./threshhold";

const config = {
  duration: 500,
};

export const Up = (props) => {
  const styles = useSpring({
    from: { transform: "translate(0px, 50px)", opacity: 0 },
    to: { transform: "translate(0px, 0px)", opacity: 1 },
    delay: props.delay,
    config: config,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 0,
        transform: "translate(0px, 50px)",
        ...props.styles,
        ...styles,
      }}
    >
      {props.children}
    </animated.div>
  );
};

export const Down = (props) => {
  const styles = useSpring({
    from: { transform: "translate(0px, -50px)", opacity: 0 },
    to: { transform: "translate(0px, 0px)", opacity: 1 },
    delay: props.delay,
    config: config,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 0,
        transform: "translate(0px, -50px)",
        ...props.styles,
        ...styles,
      }}
    >
      {props.children}
    </animated.div>
  );
};

export const Left = (props) => {
  const styles = useSpring({
    from: { transform: "translate(50px, 0px)", opacity: 0 },
    to: { transform: "translate(0px, 0px)", opacity: 1 },
    delay: props.delay,
    config: config,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 0,
        transform: "translate(50px, 0px)",
        ...props.styles,
        ...styles,
      }}
    >
      {props.children}
    </animated.div>
  );
};

export const Right = (props) => {
  const styles = useSpring({
    from: { transform: "translate(-50px, 0px)", opacity: 0 },
    to: { transform: "translate(0px, 0px)", opacity: 1 },
    delay: props.delay,
    config: config,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 0,
        transform: "translate(-50px, 0px)",
        ...props.styles,
        ...styles,
      }}
    >
      {props.children}
    </animated.div>
  );
};
