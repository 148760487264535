import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SearchIcon from "@mui/icons-material/Search";
import WindowIcon from "@mui/icons-material/Window";
import TabIcon from "@mui/icons-material/Tab";
import DvrIcon from "@mui/icons-material/Dvr";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export default function WebDesign() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="Web Design"
          subtitle="Visualize your business idea into reality"
          image={require("../img/about-banner.png")}
        />
        <ContentSideBySideInv
          title="Web Design Process"
          subtitle={
            <div>
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "orange",
                    marginRight: "15px",
                  }}
                />
                Discovery Phase
              </div>
              The Discovery Phase is the initial step in web design. It involves
              client interviews and thorough research. Its goal is to understand
              both the business and its customers’ needs. It establishes a solid
              foundation for user experience and aligns with the company’s
              overall vision​​​​​​​​​​.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "blue",
                    marginRight: "15px",
                  }}
                />
                UX Design
              </div>
              UX Design aims to create enjoyable and effective digital
              experiences. This process is centered around understanding user
              behaviors and needs. UX encompasses elements like usability,
              accessibility, and visual design. The objective is to make
              websites that are both easy to navigate and visually
              attractive​​​​​​​​​​.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "teal",
                    marginRight: "15px",
                  }}
                />
                UI Design
              </div>
              UI Design is about the visual aspects of a website. It involves
              creating engaging visual and interactive designs. The focus is on
              the website’s look and feel. Effective UI design makes a website
              engaging and user-friendly​​​​​​​​​​.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "purple",
                    marginRight: "15px",
                  }}
                />
                Support and Maintenance
              </div>
              Ongoing support addresses performance issues and keeps SEO
              practices updated. Continuous support is crucial for enhancing
              security and performance as it makes sure the website is always
              up-to-date and runs smoothly​​​​​​​​.
            </div>
          }
          image={require("../img/webd.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TravelExploreIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Responsive Websites
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Custom responsive websites with strategically and technically
                optimized content for a remarkable user experience on any
                device.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DvrIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  API Integration
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Integrating third-party solutions for enhanced visibility across
                platforms like Google, payment gateway, email, and SMS gateways.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <WindowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  CMS Website Design
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Easily upload information and graphics through a
                password-protected admin dashboard, enhancing accessibility and
                visual appeal.
              </div>
            </div>
          }
        />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <BlurOnIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Maintenance and support
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Update your website with news, events, blogs, and more through
                dynamic website design.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TabIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Payment gateway integration
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Convenient and flexible online transactions through integrated
                payment solutions.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DoubleArrowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Search Engine Optimization
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                SEO-optimized websites for higher organic ranking on SERP, which
                attract potential customers with ease.
              </div>
            </div>
          }
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
