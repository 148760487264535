import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WindowIcon from "@mui/icons-material/Window";
import TabIcon from "@mui/icons-material/Tab";
import DvrIcon from "@mui/icons-material/Dvr";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function Software() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="Mobile App Development"
          subtitle="Leading Mobile App Development Services"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="Key benefits of Mobile app Solutions for Business"
          subtitle={
            <div>
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "orange",
                    marginRight: "15px",
                  }}
                />
                Better Decision-Making
              </div>
              Artificial Intelligence (AI) and Machine Learning (ML) speed up
              data processing and programming, which reduces time and effort in
              making better decisions.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "blue",
                    marginRight: "15px",
                  }}
                />
                Accuracy{" "}
              </div>
              Prevent human errors and ensure accurate results with the help of
              Artificial Intelligence in your processes.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "teal",
                    marginRight: "15px",
                  }}
                />
                Process Optimization
              </div>
              Machine learning can be used to streamline corporate processes and
              help find anomalies in them to boost efficiency.
            </div>
          }
          image={require("../img/mobile.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TravelExploreIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Android Mobile App Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We focus on crafting customized applications for the Android
                platform. Our team utilizes Java and Kotlin to create apps that
                leverage Android’s extensive user base and versatile features.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DvrIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  IOS Mobile App Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our team excels in designing intuitive and sleek apps for Apple
                devices. By employing Swift and Objective-C, we focus on
                maximizing performance. Our ultimate aim is to enhance user
                satisfaction on iOS platforms.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <WindowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Cross Platform App Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our expertise extends to building cross-platform apps that run
                flawlessly on both Android and iOS. We utilize frameworks like
                Flutter and React Native for effective code utilization and
                experiences.
              </div>
            </div>
          }
        />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <BlurOnIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Native App Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We develop platform-specific applications to ensure optimal
                performance and seamless integration. Our expertise includes
                using Java and Kotlin for Android, and Swift and Objective-C for
                iOS which guarantee superior user experiences.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TabIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  PWA Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We create Progressive Web Apps that blend the best of web and
                mobile app features. Our PWAs are developed using HTML, CSS, and
                JavaScript. They bring users engaging experiences across devices
                and offline capabilities.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DoubleArrowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  End to End Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We offer comprehensive app development services and guide
                projects from concept to completion. This includes creativity,
                design, coding, testing, deployment, and maintenance, which
                guarantees a successful app launch.
              </div>
            </div>
          }
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
