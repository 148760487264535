import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { themeStyle } from "./Components";
import { useSpring, useSprings, useScroll, animated } from "@react-spring/web";

export default function ScrollTop() {
  const { scrollYProgress } = useScroll();
  return (
    <animated.div
      style={{
        height: "75px",
        width: "75px",
        borderRadius: "40px",
        backgroundColor: themeStyle.primaryColor,
        position: "fixed",
        right: 20,
        bottom: 20,
        zIndex: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: scrollYProgress,
        cursor: "pointer",
        color: "white",
      }}
      onClick={() => {
        window.scrollTo(0, 0);
        console.log(scrollYProgress);
      }}
      className="takemeup"
    >
      <ArrowUpwardIcon style={{ color: "white", fontSize: "40px" }} />
    </animated.div>
  );
}
