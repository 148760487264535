import * as React from "react";
import { useInView, useSpring, animated } from "@react-spring/web";
import { buildInteractionObserverThreshold } from "./threshhold";

const config = {
  duration: 500,
};

export const UpInView = (props) => {
  const [ref, isInView] = useInView({
    rootMargin: "-20% 0px -10% 0px",
    amount: buildInteractionObserverThreshold(),
  });

  const styles = useSpring({
    transform: isInView ? "translate(0px, 0px)" : "translate(0px, 50px)",
    opacity: isInView ? 1 : 0,
    config: config,
    delay: props.delay,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 1,
        transform: "translate(0px, 50px)",
        ...props.styles,
        ...styles,
      }}
      ref={ref}
    >
      {props.children}
    </animated.div>
  );
};

export const DownInView = (props) => {
  const [ref, isInView] = useInView({
    rootMargin: "-20% 0px -10% 0px",
    amount: buildInteractionObserverThreshold(),
  });

  const styles = useSpring({
    transform: isInView ? "translate(0px, 0px)" : "translate(0px, -50px)",
    opacity: isInView ? 1 : 0,
    config: config,
    delay: props.delay,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 1,
        transform: "translate(0px, -50px)",
        ...props.styles,
        ...styles,
      }}
      ref={ref}
    >
      {props.children}
    </animated.div>
  );
};

export const LeftInView = (props) => {
  const [ref, isInView] = useInView({
    rootMargin: "-20% 0px -10% 0px",
    amount: buildInteractionObserverThreshold(),
  });

  const styles = useSpring({
    transform: isInView ? "translate(0px, 0px)" : "translate(50px, 0px)",
    opacity: isInView ? 1 : 0,
    config: config,
    delay: props.delay,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 1,
        transform: "translate(50px, 0px)",
        ...props.styles,
        ...styles,
      }}
      ref={ref}
    >
      {props.children}
    </animated.div>
  );
};

export const RightInView = (props) => {
  const [ref, isInView] = useInView({
    rootMargin: "-20% 0px -10% 0px",
    amount: buildInteractionObserverThreshold(),
  });

  const styles = useSpring({
    transform: isInView ? "translate(0px, 0px)" : "translate(-50px, 0px)",
    opacity: isInView ? 1 : 0,
    config: config,
    delay: props.delay,
  });

  return (
    <animated.div
      style={{
        width: "100%",
        opacity: 1,
        transform: "translate(-50px, 0px)",
        ...props.styles,
        ...styles,
      }}
      ref={ref}
    >
      {props.children}
    </animated.div>
  );
};
