import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  ContentSideBySideCustom,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import { Grid } from "@mui/material";
import { Up, Down, Left, Right } from "../components/PositionFade";
import {
  LeftInView,
  RightInView,
  UpInView,
  DownInView,
} from "../components/PositionFade";
import { useInView, useSpring, animated } from "@react-spring/web";
import {
  useSpringCarousel,
  useTransitionCarousel,
} from "react-spring-carousel";
import { TypeAnimation } from "react-type-animation";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";

{
  /* <video src={require("../img/2.mp4")} autoPlay loop muted></video> */
}

export default function Dashboard() {
  const timelineDelays = [0, 300, 600];

  const state = useSelector((state) => state);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useTransitionCarousel({
      withLoop: true,
      items: [
        {
          id: "item-1",
          renderItem: (
            <section>
              <div class="overlay"></div>
              <img className={"imgback"} src={require("../img/doc (7).webp")} />
              <div className={"vidhero"}>
                <div style={{ width: "100%", marginTop: "-50px" }} container>
                  <Down delay={1000}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "7vh",
                        display: "inline-block",
                        lineHeight: "80px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Book Your Visit in Seconds
                    </div>
                  </Down>
                  <br />
                  <Down delay={1500}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "25px",
                        display: "inline-block",
                        lineHeight: "40px",
                        textAlign: "center",
                      }}
                    >
                      Find trusted healthcare professionals near you and
                      schedule appointments with ease.
                    </div>
                  </Down>
                </div>
              </div>
            </section>
          ),
        },
        {
          id: "item-2",
          renderItem: (
            <section>
              <div class="overlay"></div>
              <img className={"imgback"} src={require("../img/doc (4).webp")} />
              <div className={"vidhero"}>
                <div style={{ width: "100%", marginTop: "-50px" }} container>
                  <Down delay={1000}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "7vh",
                        display: "inline-block",
                        lineHeight: "80px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Streamline Your Medical Rep Visits{" "}
                    </div>
                  </Down>
                  <br />
                  <Down delay={1500}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "25px",
                        display: "inline-block",
                        lineHeight: "40px",
                        textAlign: "center",
                      }}
                    >
                      Easily schedule appointments with healthcare professionals
                      to optimize your time and sales
                    </div>
                  </Down>
                </div>
              </div>
            </section>
          ),
        },
        {
          id: "item-3",
          renderItem: (
            <section>
              <div class="overlay"></div>
              <img className={"imgback"} src={require("../img/doc (9).webp")} />
              <div className={"vidhero"}>
                <div style={{ width: "100%", marginTop: "-50px" }} container>
                  <Down delay={1000}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "7vh",
                        display: "inline-block",
                        lineHeight: "80px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Boost Your Productivity with Easy Scheduling{" "}
                    </div>
                  </Down>
                  <br />
                  <Down delay={1500}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "25px",
                        display: "inline-block",
                        lineHeight: "40px",
                        textAlign: "center",
                      }}
                    >
                      Save time, increase efficiency, and connect with the right
                      healthcare professionals
                    </div>
                  </Down>
                </div>
              </div>
            </section>
          ),
        },
        {
          id: "item-4",
          renderItem: (
            <section>
              <div class="overlay"></div>
              <img className={"imgback"} src={require("../img/doc (6).webp")} />
              <div className={"vidhero"}>
                <div style={{ width: "100%", marginTop: "-50px" }} container>
                  <Down delay={1000}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "7vh",
                        display: "inline-block",
                        lineHeight: "80px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Simplified Appointments for Medical Representatives
                    </div>
                  </Down>
                  <br />
                  <Down delay={1500}>
                    <div
                      style={{
                        marginTop: state.auth.isMobile ? "-50px" : 0,
                        marginLeft: state.auth.isMobile ? "-25px" : 0,
                        fontSize: "25px",
                        display: "inline-block",
                        lineHeight: "40px",
                        textAlign: "center",
                      }}
                    >
                      Book and organize your appointments with doctors and
                      healthcare institutions seamlessly
                    </div>
                  </Down>
                </div>
              </div>
            </section>
          ),
        },
      ],
    });

  useEffect(() => {
    const timer = setInterval(() => {
      slideToNextItem();
    }, 15000);
    return () => {
      window.clearInterval(timer);
    };
  }, [slideToNextItem]);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div
          style={{
            height: "80vh",
            width: "100vw",
            paddingTop: "75px",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            {carouselFragment}
          </div>
        </div>
        <ContentSideBySideCustom
          background={"white"}
          titleFontSize={"35px"}
          subtitleFontSize={"25px"}
          title="Effortless Appointments of Doctors for Medical Representatives"
          subtitle="Streamline your Daily appointments with Healthcare professionals. Our Mobile App – (Appointee ) helps medical Representatives easily find , Schedule and manage meetings, ensuring your're always on top and save on time."
          image={
            <img
              style={{ height: "100%", width: "100%" }}
              src={require("../img/cn-img.png")}
            />
          }
        />
        <SpacerV height={"100px"} />
        <div
          style={{
            paddingTop: "100px",
            paddingBottom: "100px",
            background: themeStyle.backgroundGradient,
          }}
        >
          <TabContext value={value}>
            {!state.auth.isMobile ? (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "50px",
                  background: "transparent",
                  maxWidth: "100vw",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Optimal"
                    value="1"
                    style={{
                      fontSize: "25px",
                      color: themeStyle.primaryColor,
                      fontFamily: "Karla",
                      fontWeight: value == 1 ? "bold" : "lighter",
                    }}
                  />
                  <Tab
                    label="Smart"
                    value="2"
                    style={{
                      fontSize: "25px",
                      color: themeStyle.primaryColor,
                      fontFamily: "Karla",
                      fontWeight: value == 2 ? "bold" : "lighter",
                    }}
                  />
                  <Tab
                    label="Easy"
                    value="3"
                    style={{
                      fontSize: "25px",
                      color: themeStyle.primaryColor,
                      fontFamily: "Karla",
                      fontWeight: value == 3 ? "bold" : "lighter",
                    }}
                  />
                  <Tab
                    label="Essential"
                    value="4"
                    style={{
                      fontSize: "25px",
                      color: themeStyle.primaryColor,
                      fontFamily: "Karla",
                      fontWeight: value == 4 ? "bold" : "lighter",
                    }}
                  />
                  <Tab
                    label="Efficient"
                    value="5"
                    style={{
                      fontSize: "25px",
                      color: themeStyle.primaryColor,
                      fontFamily: "Karla",
                      fontWeight: value == 5 ? "bold" : "lighter",
                    }}
                  />
                </TabList>
              </Box>
            ) : (
              ""
            )}
            <TabPanel value="1">
              <ContentSideBySideCustom
                background={"transparent"}
                titleColor={themeStyle.primaryColor}
                titleFontSize={"35px"}
                subtitleFontSize={"25px"}
                title="Optimize Your Medical Sales Visits"
                subtitle="Book appointments with doctors and healthcare providers faster than ever. Stay organized, manage your time efficiently, and make the most of every meeting with real-time scheduling and reminders."
                image={
                  <img
                    style={{
                      height: "50vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={require("../img/doc (8).webp")}
                  ></img>
                }
              />
            </TabPanel>
            <TabPanel value="2">
              <ContentSideBySideCustom
                background={"transparent"}
                titleColor={themeStyle.primaryColor}
                titleFontSize={"35px"}
                subtitleFontSize={"25px"}
                title="Smart Appointment Management for Medical Representatives"
                subtitle="Our app offers an all-in-one solution for medical Representatives to schedule and manage appointments with doctors. Stay in control of your calendar and minimize downtime between visits."
                image={
                  <img
                    style={{
                      height: "50vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={require("../img/doc (3).webp")}
                  ></img>
                }
              />
            </TabPanel>
            <TabPanel value="3">
              <ContentSideBySideCustom
                background={"transparent"}
                titleColor={themeStyle.primaryColor}
                titleFontSize={"35px"}
                subtitleFontSize={"25px"}
                title="Maximize Your Time with Easy Scheduling"
                subtitle="Make every minute count with quick and seamless scheduling. Connect with healthcare professionals, view their availability, and set up meetings that fit into your busy day"
                image={
                  <img
                    style={{
                      height: "50vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={require("../img/doc (1).webp")}
                  ></img>
                }
              />
            </TabPanel>
            <TabPanel value="4">
              {" "}
              <ContentSideBySideCustom
                background={"transparent"}
                titleColor={themeStyle.primaryColor}
                titleFontSize={"35px"}
                subtitleFontSize={"25px"}
                title="The Essential Tool for Medical Representatives"
                subtitle="Take control of your workday by booking appointments efficiently. Our app simplifies the process of reaching out to healthcare professionals and managing your meetings, so you can focus on sales."
                image={
                  <img
                    style={{
                      height: "50vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={require("../img/doc (10).webp")}
                  ></img>
                }
              />
            </TabPanel>
            <TabPanel value="5">
              <ContentSideBySideCustom
                background={"transparent"}
                titleColor={themeStyle.primaryColor}
                titleFontSize={"35px"}
                subtitleFontSize={"25px"}
                title="Save Time, Increase Efficiency"
                subtitle="Eliminate the hassle of scheduling by using our app to manage appointments with healthcare providers. Enjoy instant booking, calendar sync, and automated reminders, keeping you organized on the go"
                image={
                  <img
                    style={{
                      height: "50vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={require("../img/doc (5).webp")}
                  ></img>
                }
              />
            </TabPanel>
          </TabContext>
        </div>

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
