import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WindowIcon from "@mui/icons-material/Window";
import TabIcon from "@mui/icons-material/Tab";
import DvrIcon from "@mui/icons-material/Dvr";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function Software() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="Software Development"
          subtitle="Software Development Services for the Evolving Digital Era"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="Key benefits of Software Solutions for Business"
          subtitle={
            <div>
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "orange",
                    marginRight: "15px",
                  }}
                />
                Better Decision-Making
              </div>
              Artificial Intelligence (AI) and Machine Learning (ML) speed up
              data processing and programming, which reduces time and effort in
              making better decisions.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "blue",
                    marginRight: "15px",
                  }}
                />
                Accuracy{" "}
              </div>
              Prevent human errors and ensure accurate results with the help of
              Artificial Intelligence in your processes.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "teal",
                    marginRight: "15px",
                  }}
                />
                Process Optimization
              </div>
              Machine learning can be used to streamline corporate processes and
              help find anomalies in them to boost efficiency.
            </div>
          }
          image={require("../img/soft.png")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TravelExploreIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Development
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our approach to creating solutions that perfectly fit your needs
                involves developing customized software that enhances efficiency
                and innovation. We seamlessly integrate with your existing
                systems .
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DvrIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Solution
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We are here to turn your complex challenges into simple and
                streamlined solutions. We engineer comprehensive and scalable
                software solutions. Our agile team has intuitive interfaces.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <WindowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Maintenance
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our devoted support will take your business excellence to its
                peak. They will ensure your software stays robust, secure, and
                consistently updated. It will help you reduce downtime and
                enhance user satisfactio.
              </div>
            </div>
          }
        />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <BlurOnIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Consulting
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our experts will provide insightful strategies, technology
                recommendations, and detailed project roadmaps. They will align
                with emerging trends such as advanced cybersecurity and cloud
                computing technologies.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TabIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Delivery
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                We offer remote expert collaboration for designing, developing,
                and delivering top-notch software solutions for you. Our support
                is tailored exactly to drive your business’s success in the
                dynamic tech world of 2024.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DoubleArrowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Software Integration
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                Our software integration assistance will seamlessly connect your
                digital ecosystem. Our skilled integration services streamline
                your processes and optimize data flow. It will increase
                efficiency and organization across your organization.
              </div>
            </div>
          }
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
