import React from "react";
import { themeStyle, themeStyleDark } from "./Components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Up } from "./PositionFade";
import { UpInView } from "./PositionFadeInView";

export default function Footer(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 600, 1200, 1800, 2400];
  const timelineDelays2 = [100, 200, 300, 400, 600, 800, 1000, 1200];
  const timelineDelays3 = [700, 800, 900];

  const timelineDelays4 = [1300, 1400, 1500];
  const timelineDelays5 = [1900];

  return (
    <div
      className="footer"
      style={{
        minHeight: "60vh",
        width: "100vw",
        backgroundColor: "#03A9F4",
        padding: "100px",
      }}
    >
      <Grid container spacing={5}>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <UpInView delay={timelineDelays[0]}>
            <div
              style={{
                fontSize: "30px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Appointee App
            </div>
          </UpInView>
          <div
            style={{
              margin: "15px 0px 50px 0px",
              width: "30px",
              height: "3px",
              backgroundColor: themeStyle.primaryColor,
            }}
          ></div>
          <UpInView delay={timelineDelays2[0]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
              }}
            >
              About Us
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays2[1]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
              }}
            >
              Contact Us
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays2[2]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
              }}
            >
              Careers
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays2[3]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
              }}
            >
              Privacy Policy
            </div>
          </UpInView>

          <br />
          <UpInView delay={timelineDelays2[4]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
              }}
            >
              Terms and conditions
            </div>
          </UpInView>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <UpInView delay={timelineDelays[1]}>
            <div
              style={{
                fontSize: "30px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Global Office
            </div>
          </UpInView>
          <div
            style={{
              margin: "15px 0px 50px 0px",
              width: "30px",
              height: "3px",
              backgroundColor: "#1868DD",
            }}
          ></div>
          <UpInView delay={timelineDelays3[0]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "15px",
                }}
              />{" "}
              Shreekrupa Infotech, India
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays3[1]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PhoneInTalkIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "15px",
                }}
              />{" "}
              +91 84849 06268
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays3[2]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmailIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "15px",
                }}
              />{" "}
              support@appointeeapp.in
            </div>
          </UpInView>
          <br />
          <UpInView delay={timelineDelays3[2]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmailIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "15px",
                }}
              />{" "}
              info@appointeeapp.in
            </div>
          </UpInView>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          {" "}
        </Grid>

        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <UpInView delay={timelineDelays[3]}>
            <div
              style={{
                fontSize: "30px",
                color: "white",
              }}
            >
              Address
            </div>
          </UpInView>
          <div
            style={{
              margin: "15px 0px 50px 0px",
              width: "30px",
              height: "3px",
              backgroundColor: "#1868DD",
            }}
          ></div>
          <UpInView delay={timelineDelays5[0]}>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "15px",
                }}
              />{" "}
              Shreekrupa Infotech, India
            </div>
          </UpInView>
          <br />
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{ marginTop: "50px" }}>
        <Grid
          item
          md={3}
          sm={12}
          xs={12}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CopyrightIcon
              style={{
                color: "white",
                fontSize: "30px",
                marginRight: "15px",
              }}
            />{" "}
            2024 Shreekrupa Infotech <br />
            All Rights Reserved.
          </div>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div
            style={{
              borderRadius: "25px",
              width: "50%",
              height: "50px",
              fontSize: "26px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Appointee
            <div
              style={{
                fontSize: "20px",
                fontWeight: "lighter",
                marginTop: "-10px",
              }}
            >
              {" "}
              Your appointment companion
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
          xs={12}
          style={{
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              onClick={() =>
                window.open(
                  "https://in.linkedin.com/company/cgtsolutions",
                  "_blank"
                )
              }
              style={{
                border: "1px white solid",
                borderRadius: "40px",
                width: "50px",
                height: "50px",
                fontSize: "20px",
                color: "white",
                marginRight: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <XIcon style={{ color: "white", fontSize: "30px" }} />
            </div>
            <div
              onClick={() =>
                window.open(
                  "https://in.linkedin.com/company/cgtsolutions",
                  "_blank"
                )
              }
              style={{
                border: "1px white solid",
                borderRadius: "40px",
                width: "50px",
                height: "50px",
                fontSize: "20px",
                color: "white",
                marginRight: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinkedInIcon style={{ color: "white", fontSize: "30px" }} />
            </div>

            <div
              onClick={() =>
                window.open(
                  "https://in.linkedin.com/company/cgtsolutions",
                  "_blank"
                )
              }
              style={{
                border: "1px white solid",
                borderRadius: "40px",
                width: "50px",
                height: "50px",
                fontSize: "20px",
                color: "white",
                marginRight: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FacebookIcon style={{ color: "white", fontSize: "30px" }} />
            </div>
            <div
              onClick={() =>
                window.open(
                  "https://in.linkedin.com/company/cgtsolutions",
                  "_blank"
                )
              }
              style={{
                border: "1px white solid",
                borderRadius: "40px",
                width: "50px",
                height: "50px",
                fontSize: "20px",
                color: "white",
                marginRight: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InstagramIcon style={{ color: "white", fontSize: "30px" }} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
