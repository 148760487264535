import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";

export default function About() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="About Appointee"
          subtitle="Who we are"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="How we started"
          subtitle="At Appointee, we understand the challenges medical representatives face in securing appointments with busy doctors. Time constraints, packed schedules, and administrative barriers often make it difficult to efficiently connect with healthcare professionals. Appointee is here to simplify that process. Our platform streamlines appointment booking by allowing medical reps to schedule meetings with doctors through a user-friendly mobile app. With features like real-time availability, automated reminders, and secure messaging, we help reps save time and focus on their core responsibilities. Appointee is designed to empower medical professionals, making appointments hassle-free and increasing productivity for both parties."
          image={require("../img/banner-1.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ContentSideBySide
          title="What makes us who we are today…"
          subtitle="Appointee is a team of strategists, creative directors, designers, technologists, developers, and relationship builders. We are committed to creating vibrant and relevant experiences for our clients at every level, using our product and technology expertise.​​"
          image={require("../img/banner-2.jpg")}
        />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          title1="Effortless"
          subtitle1={
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <div>
                Instantly find and book available healthcare providers.{" "}
              </div>
              <br />
              <div>Avoid double-bookings with real-time calendar sync. </div>
              <br />
              <div>
                Set appointments in just a few taps, with automated
                confirmations.
              </div>
            </div>
          }
          title2="Smart"
          subtitle2={
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <div>View and manage all your appointments in one place.</div>
              <br />
              <div>
                Receive notifications and reminders to stay on schedule.
              </div>
              <br />
              <div>Sync seamlessly with your personal and work calendars.</div>
            </div>
          }
          title3="Seamless"
          subtitle3={
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <div>
                Send direct appointment requests to healthcare professionals.
              </div>
              <br />
              <div>
                Track upcoming meetings and keep detailed appointment logs.
              </div>
              <br />
              <div>
                Get real-time updates and confirmations to reduce no-shows.
              </div>
            </div>
          }
          image1={require("../img/idea.png")}
          image2={require("../img/action.png")}
          image3={require("../img/growth.png")}
        />
        <SpacerV height={"100px"} />
        <ContentSideBySideInv
          title="Empowering Medical Representatives with Smart Scheduling Tools"
          subtitle="Our mission is to empower medical representatives to work smarter, not harder. We understand the challenges of coordinating multiple appointments in a fast-paced industry, which is why we created an app that eliminates the complexities of scheduling."
          image={require("../img/med1.webp")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
