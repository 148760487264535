import React, { useState, useEffect } from "react";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  ContentSideBySideCustomInv,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../actions/auth";
import { alertMessage } from "../actions/auth";

export default function ContactUs() {
  useEffect(() => {}, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  function onSubmit(e) {
    e.preventDefault();
    var body = {};
    body["name"] = name;
    body["email"] = email;
    body["phone_number"] = phoneNumber;
    body["subject"] = subject;
    body["content"] = description;

    axios
      .post(API_URL + "add_contact", JSON.stringify(body))
      .then((res) => {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setDescription("");
        setSubject("");
        dispatch(
          alertMessage(
            "SUCCESS",
            "SUCCESS",
            "Your request has been submitted. Our team will get back to you."
          )
        );
      })
      .catch((err) => {});
  }

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <SpacerV height={"100px"} />

        <Hero
          background={themeStyle.backgroundGradient}
          title="Contact Us"
          subtitle="Get in touch"
          image={require("../img/contact-bg.png")}
        />
        <ContentSideBySideCustomInv
          background={"white"}
          titleColor={"black"}
          titleFontSize={"35px"}
          subtitleFontSize={"30px"}
          title={<div>Have a question?</div>}
          subtitle={
            <form style={{ padding: "15px" }} onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid " + themeStyle.primaryColor,
                      },
                      "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                        {
                          color: themeStyle.primaryColor,
                          fontWeight: "bold",
                          opacity: 1,
                        },
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    placeholder="Name *"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "#444", navOpacity: 1 },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid " + themeStyle.primaryColor,
                      },
                      "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                        {
                          color: themeStyle.primaryColor,
                          fontWeight: "bold",
                          opacity: 1,
                        },
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    placeholder="E-mail *"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "#444", navOpacity: 1 },
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid " + themeStyle.primaryColor,
                      },
                      "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                        {
                          color: themeStyle.primaryColor,
                          fontWeight: "bold",
                          opacity: 1,
                        },
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="phonenumber"
                    autoComplete="phonenumber"
                    autoFocus
                    placeholder="Phone no. *"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "#444", navOpacity: 1 },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.backgroundColor
                          : themeStyle.backgroundColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid " + themeStyle.primaryColor,
                      },
                      "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                        {
                          color: themeStyle.primaryColor,
                          fontWeight: "bold",
                          opacity: 1,
                        },
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="subject"
                    autoComplete="subject"
                    autoFocus
                    placeholder="Subject *"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "#444", navOpacity: 1 },
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.backgroundColor
                      : themeStyle.backgroundColor,
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                  borderRadius: "6px",
                  height: "400",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid " + themeStyle.primaryColor,
                  },
                  "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                    color: themeStyle.primaryColor,
                    fontWeight: "bold",
                    opacity: 1,
                  },
                }}
                variant="outlined"
                required
                fullWidth
                margin="normal"
                name="description"
                autoComplete="description"
                multiline
                rows={7}
                autoFocus
                placeholder="Description of your enquiry *"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  dispatch({
                    type: "NAV_COLOR",
                    payload: { navColor: "#444", navOpacity: 1 },
                  });
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "60px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonColor
                        : themeStyle.primaryButtonColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                  }}
                  type="submit"
                  variant="contained"
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    SUBMIT REQUEST
                  </span>
                </Button>
              </div>
            </form>
          }
          image={
            <img
              style={{ height: "50vh" }}
              src={require("../img/cn-img.png")}
            ></img>
          }
        />
        <SpacerV height={"100px"} />

        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
