import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import axios from "axios";
import Button from "@mui/material/Button";
import { API_URL } from "../actions/auth";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { alertMessage } from "../actions/auth";
import TextField from "@mui/material/TextField";

export default function Careers() {
  const [vacancies, setVacancies] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get(API_URL + "get_vacancies").then((res) => {
      setVacancies(res.data);
    });
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobId, setJobId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  function onSubmit(e) {
    e.preventDefault();
    var body = {};
    body["name"] = name;
    body["email"] = email;
    body["phone_number"] = phoneNumber;
    body["subject"] = subject;
    body["description"] = description;
    body["job_id"] = jobId;
    axios
      .post(API_URL + "add_job", JSON.stringify(body))
      .then((res) => {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setDescription("");
        setSubject("");
        setOpen(false);
        dispatch(
          alertMessage(
            "SUCCESS",
            "SUCCESS",
            "Your request has been submitted. Our team will get back to you."
          )
        );
      })
      .catch((err) => {});
  }

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                backgroundColor: "white",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                padding: "20px",
                width: "40vw",
              }}
            >
              <h2>Apply for {jobName}</h2>
              <form style={{ padding: "15px" }} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: 600,
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      placeholder="Name *"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: 600,
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      placeholder="E-mail *"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: 600,
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Phone number *"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: 600,
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="subject"
                      autoComplete="subject"
                      autoFocus
                      placeholder="Subject *"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.backgroundColor
                        : themeStyle.backgroundColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    borderRadius: "6px",
                    height: "400",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid " + themeStyle.primaryColor,
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                      {
                        color: themeStyle.primaryColor,
                        fontWeight: 600,
                        opacity: 1,
                      },
                  }}
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  name="description"
                  autoComplete="description"
                  multiline
                  rows={7}
                  autoFocus
                  placeholder="Description of your enquiry with link to your resume *"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "#444", navOpacity: 1 },
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonColor
                          : themeStyle.primaryButtonColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    type="submit"
                    variant="contained"
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      SUBMIT REQUEST
                    </span>
                  </Button>
                </div>
              </form>
            </div>
          </Fade>
        </Modal>
        <Hero
          background={themeStyle.backgroundGradient}
          title="Job vacancies & Internships"
          subtitle="See latest opportunities and apply for your dream job"
          image={require("../img/about-banner.png")}
        />
        <SpacerV height={"100px"} />
        <div
          style={{
            borderRadius: "25px",
            fontSize: "56px",
            color: themeStyle.primaryColor,
            fontWeight: "bold",
            backgroundColor: "white",
            paddingBottom: "50px",
          }}
        >
          Opportunities
        </div>
        <Grid container style={{ backgroundColor: "white" }}>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid container spacing={10}>
              {vacancies.map((vacancy, index) => (
                <Grid
                  item
                  md={4}
                  xs={12}
                  sm={12}
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "start",
                    minHeight: "50vh",
                  }}
                >
                  <div
                    style={{
                      minHeight: "36vh",
                      padding: "20px",
                      paddingTop: "15px",
                      borderRadius: "10px",
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow:
                        "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    <PersonSearchIcon
                      style={{
                        fontSize: "100px",
                        color: themeStyle.primaryColor,
                        marginRight: "15px",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          display: "flex",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "30px",
                          marginTop: "15px",
                        }}
                      >
                        {vacancy.name}
                      </div>
                      <div
                        style={{
                          margin: "15px 0px 15px 0px",
                          width: "60px",
                          height: "3px",
                          backgroundColor: themeStyle.primaryColor,
                        }}
                      ></div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: themeStyle.primaryColor,
                        }}
                      >
                        {vacancy.location}
                      </div>
                      <div
                        style={{
                          margin: "15px 0px 15px 0px",
                          width: "60px",
                          height: "3px",
                          backgroundColor: themeStyle.primaryColor,
                        }}
                      ></div>
                      {vacancy.description}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        </Grid>
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
