import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";

export default function Terms() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="Terms and Conditions"
          subtitle="Please read these carefully"
          image={require("../img/about-banner.png")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Grid
          container
          style={{
            backgroundColor: "white",
          }}
        >
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div
              style={{
                padding: "50px",
                fontSize: "26px",
                textAlign: "justify",
                backgroundColor: "white",
              }}
            >
              <b>Terms and Conditions</b>
              <br />
              <br />
              By downloading, browsing, accessing or using this Appointee mobile
              application as well as website, you agree to be bound by these
              terms and conditions of use. we reserve right to amend this terms
              and conditions at any time. If you disagree with any of this terms
              and conditions of use, you must immediately discontinue your
              access to the mobile application as well as website and your use
              of the services offered on the mobile application as well as
              website.
              <br /> <br /> 1. Your data safety & Privacy is very important to
              us. Appointee app stores your information on secure server and
              never share it. <br /> <br /> 2. Appointee app does not give to
              surety to meet the doctor/cancel appointment/lapsed appointment{" "}
              <br /> <br /> 3. If your appointment booked on that day and doctor
              is not available application is not responsible to make your call
              of the doctor <br /> <br /> 4. Appointee app /web is running by
              internet and 3rd party Server. Be patience and Co operate with Us
              during technical problems like slow internet, server down,
              updating of App/web etc.
              <br /> <br /> 5. Whenever any issues raised in Appointee app /Web
              then directly contact to us in this situation to be polite with
              receptionist. <br /> <br />
              6. If Doctor Blocked any MR then Appointee app is not responsible
              to make call this Doctor <br /> <br /> 7. Appointee app App/web
              contain most of Doctor. We can not claim this app /web contain
              100% Doctor list. Our system is growing so, time to time updating
              is there. <br /> <br />
              8. The rights approved to you in these Terms are subject to the
              following restrictions: <br /> A) You shall not sell, rent, lease,
              transfer, assign, distribute, host, or otherwise commercially
              exploit the our App/Web; <br /> B) You shall not change, make
              derivative works of, disassemble, reverse compile or reverse
              engineer any part of the App/Web; <br /> C) You shall not access
              the App/Site in order to build a similar or competitive website;{" "}
              <br /> D) Except as expressly stated herein, no part of the App
              and Web may be copied, reproduced, distributed, republished,
              downloaded, displayed, posted or transmitted in any form or by any
              means unless otherwise indicated, any future release, update, or
              other addition to functionality of the App/Web shall be subject to
              these Terms. <br /> E) All copyright and other proprietary notices
              on the App or Site must be retained on all copies thereof. <br />{" "}
              F) Appointee app reserves the right to change, suspend, or cease
              the App/Web with or without notice to you. You approved that
              Appointee app will not be held liable to you or any third-party
              for any change, interruption, or termination of the App/Web or any
              part. <br /> <br /> 9. Appointee app App/Web is provided on an
              “as-is” and “as available” basis, and Appointee app and our
              suppliers expressly disclaim any and all warranties and conditions
              of any kind, whether express, implied, or statutory, including all
              warranties or conditions of merchantability, fitness for a
              particular purpose, title, quiet enjoyment, accuracy, or
              non-infringement.We and our suppliers make not guarantee that the
              app and site will meet your requirements, will be available on an
              uninterrupted, timely, secure, or error-free basis, or will be
              accurate, reliable, free of viruses or other harmful code,
              complete, legal, or safe. <br /> <br /> 10. These Terms constitute
              the entire agreement between you and us regarding the use of the
              Site. Our failure to exercise or enforce any right or provision of
              these Terms shall not operate as a waiver of such right or
              provision. The section titles in these Terms are for convenience
              only and have no legal or contractual effect. The word
              \”including\” means \”including without limitation\”. If any
              provision of these Terms is held to be invalid or unenforceable,
              the other provisions of these Terms will be unimpaired and the
              invalid or unenforceable provision will be deemed modified so that
              it is valid and enforceable to the maximum extent permitted by
              law. Your relationship to Company is that of an independent
              contractor, and neither party is an agent or partner of the other.
              These Terms, and your rights and obligations herein, may not be
              assigned, subcontracted, delegated, or otherwise transferred by
              you without Appointee app prior written consent, and any attempted
              assignment, subcontract, delegation, or transfer in violation of
              the foregoing will be null and void. Appointee app may freely
              assign these Terms. The terms and conditions set forth in these
              Terms shall be binding upon assignees. <br />
              <br />
              <b> Refund and Cancellation Policy </b>
              <br />
              Last Updated: Thank you for shopping
              at https://www.appointeeapp.in If for any reason, You are not
              completely satisfied with a p urchase, we invite You to review our
              Policy. The following terms are applicable for any products that
              you purchased with us. <br />
              <br />
              <b> Definitions</b>
              <br />
              For the purposes of this Return and Exchange Policy: Business
              Company (referred to as either “the Company”, “We”, “Us” or “Our”
              in this Agreement) refers to Appointee app Located at Nasik, IN.
              Goods refer to the items offered for sale on the Service.
              Orders mean a request by You to purchase Goods from Us.
              Service refers to the Website. Website refers to our Site,
              accessible from https://www.appointeeapp.in You means the
              individual accessing or using the Service, or the company, or
              other legal entity on behalf of which such individual is accessing
              or using the Service, as applicable.
              <br />
              <br /> <b> Refund Policy For APPOINTEE </b>
              <br />
              Refunds of Digital/Subscription Based Goods: APPOINTEE is
              committed to your satisfaction. If you have purchased a digital
              subscription/service from APPOINTEE and you may NOT be eligible
              for a refund/partial refund. APPOINTEE is committed to its users,
              and while we stand by our policy as written above, we also want to
              understand how we can resolve the dissatisfaction and better
              understand how we can serve you. Please contact APPOINTEE
              at info@appointeeapp.in for any questions related to our policy,
              or simply to let us know how we can help.
              <br />
              <br />
              <b>
                The Information Technology Act (IT Act) and the Indian Penal
                Code (IPC) can be used to address online harassment on
                WhatsApp:  Section 66A of the IT Act Prohibits sending offensive
                messages through a communication device, such as WhatsApp.
              </b>
              <br />
              <br />
              The punishment for this offense is up to three years in prison and
              a fine.  Section 67 of the IT Act Deals with publishing or
              transmitting obscene material in electronic form.  Section 499 of
              the IPC Can be used if someone uses words to hurt your reputation
              in front of others.  Section 503 of the IPC Can be used if someone
              threatens to cause injury to your reputation to scare you or make
              you do something you don't want to do. 
              <br />
              <br />
              <b>
                WhatsApp's Terms of Service prohibit sharing content that is
                illegal, obscene, defamatory, threatening, harassing, or
                hateful. You can report problematic content to WhatsApp or block
                the user to stop receiving their messages, calls, and status
                updates
              </b>
              <br />
            </div>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        </Grid>

        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
