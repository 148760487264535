import "./App.css";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import WebDesign from "./pages/WebDesign";
import MobileApp from "./pages/MobileApp";
import AIandML from "./pages/AIandML";
import Software from "./pages/Software";
import UIUX from "./pages/UIUX";
import DigitalMarketing from "./pages/DigitalMarketing";
import Careers from "./pages/Careers";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 1300) {
      dispatch({ type: "MOBILE_VIEW", payload: true });
    } else {
      dispatch({ type: "MOBILE_VIEW", payload: false });
    }
  }
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/uiux" element={<UIUX />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/webdesign" element={<WebDesign />} />
        <Route path="/mobileapp" element={<MobileApp />} />
        <Route path="/ai_ml" element={<AIandML />} />
        <Route path="/marketing" element={<DigitalMarketing />} />
        <Route path="/software" element={<Software />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
    </div>
  );
}

export default App;
