import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";

export default function DigitalMarketing() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="ABOUT PURVIEW"
          subtitle="Who we are"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="How we started"
          subtitle="Purview, a global IT company founded in 2010, has continuously grown and evolved over the past decade. Led by Founder and CEO Mr. Punna Reddy, Purview has embraced change, brought new ideas to the table, and taken on challenging projects to become a leader in the industry.​Purview's entrepreneurial journey has been centered on building and maintaining relationships with the best in the world, enabling the company to continuously pursue its goal of perfection. Purview's focus on outstanding quality has been unwavering, from introducing path-breaking technologies to setting new standards of service in the IT sector.​ Today, Purview celebrates its business achievements and recognizes the employees who have played a vital role in reaching important milestones. The company is more dedicated than ever to delivering its clients the best possible services and solutions.​"
          image={require("../img/banner-1.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ContentSideBySide
          title="What makes us who we are today…"
          subtitle="Purview is a team of strategists, creative directors, designers, technologists, developers, and relationship builders. We are committed to creating vibrant and relevant experiences for our clients at every level, using our product and technology expertise.​In the era of digital transformation, we deliver human-shaped experiences at the intersection of strategy, design, and technology. We have invested in building strong competency, innovation hubs, and labs to cater to cutting-edge technologies like Cybersecurity, cloud, Internet of Things (IoT), and Artificial Intelligence (AI).​​"
          image={require("../img/banner-2.jpg")}
        />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          title1="Idea"
          subtitle1={
            <div>
              <li>Have access to great talent and expertise</li>
              <li>A Value Creation Approach</li>
              <li>
                Scale and scope of production to reduce per-unit costs and
                increase efficiency
              </li>
            </div>
          }
          title2="Action"
          subtitle2={
            <div>
              <li>Initiatives for employee career development and training</li>
              <li>
                Customer-centric and are laser-focused on providing amazing
                experiences
              </li>
              <li>
                An abundance of internal resources to execute any kind of
                project
              </li>
            </div>
          }
          title3="Idea"
          subtitle3={
            <div>
              <li>A strong framework that makes execution easier</li>
              <li>
                Build a Risk Management Strategy to Improve Product quality and
                minimize risks
              </li>
              <li>A strong framework that makes execution easier</li>
            </div>
          }
          image1={require("../img/idea.png")}
          image2={require("../img/action.png")}
          image3={require("../img/growth.png")}
        />
        <SpacerV height={"100px"} />
        <ContentSideBySideInv
          title="Strategic Leadership for Global Growth AJ Venter Joins PURVIEW"
          subtitle="In an era where authenticity, genuine connections, and human touch are paramount, PURVIEW is thrilled to welcome AJ Venter aboard as our new Global Chief Relations Officer! With his contagious enthusiasm and proven track record in nurturing robust global relationships, AJ brings precisely the expertise we need to fortify and expand our partnerships worldwide. Your pivotal role is indispensable to our continuous growth and triumph, and we eagerly anticipate witnessing how your leadership will elevate our reputation and foster stakeholder engagement across the globe. We're truly excited for you to infuse your unique skills and boundless passion into our collective efforts. Welcome to the team, AJ!"
          image={require("../img/ajventer.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          title1="USFCR Verified Vendor"
          subtitle1={""}
          title2="Living Wage Employers"
          subtitle2={""}
          title3="ISO28001 Certified"
          subtitle3={""}
          image1={require("../img/verified.png")}
          image2={require("../img/livingwage.png")}
          image3={require("../img/iso.png")}
        />
        <SpacerV height={"100px"} />

        <Gallery
          gallery1={[
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person1.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person2.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person3.jpg"),
            },
          ]}
          gallery2={[
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person3.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person2.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person1.jpg"),
            },
          ]}
          gallery3={[
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person3.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person1.jpg"),
            },
            {
              subtitle: "Person Surname",
              title: "Marketing Director",
              image: require("../img/person2.jpg"),
            },
          ]}
        />
        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
