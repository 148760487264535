import React from "react";
import { themeStyle, themeStyleDark } from "./Components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Up, Down, Left, Right } from "../components/PositionFade";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSpring, useSprings, useScroll, animated } from "@react-spring/web";
import { useNavigate } from "react-router";

import {
  LeftInView,
  RightInView,
  UpInView,
  DownInView,
} from "./PositionFadeInView";

const config = {
  duration: 500,
};

export function SpacerV(props) {
  return (
    <div
      style={{ height: props.height, width: "100%", backgroundColor: "white" }}
    />
  );
}

export function SpacerH(props) {
  return (
    <div
      style={{ width: props.width, height: "100%", backgroundColor: "white" }}
    />
  );
}

export function CardHorizontal(props) {
  const [menuContainer, menuContainerApi] = useSpring(() => ({
    from: { transform: "scale(1)" },
  }));
  const navigate = useNavigate();

  return (
    <animated.div
      style={{
        padding: "10px",
        paddingLeft: "15px",
        borderRadius: "5px",
        width: "100%",
        backgroundcolor: themeStyle.primaryColor,
        boxShadow:
          "0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        transform: "scale(1)",
        ...menuContainer,
      }}
      onClick={() => {
        navigate("/" + props.navigate);
      }}
      onMouseEnter={() => {
        menuContainerApi.start({
          from: {
            transform: "scale(1)",
          },
          to: {
            transform: "scale(1.05)",
          },
        });
      }}
      onMouseLeave={() => {
        menuContainerApi.start({
          from: {
            transform: "scale(1.05)",
          },
          to: {
            transform: "scale(1)",
          },
        });
      }}
    >
      <div style={{ marginTop: "5px" }}>{props.content}</div>
      <div>
        <div
          style={{
            textAlign: "left",
            marginLeft: "15px",
            borderRadius: "25px",
            fontSize: "20px",
            color: "black",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            marginTop: "-5px",
            marginLeft: "15px",
            borderRadius: "25px",
            fontSize: "16px",
            color: "#222222",
            textAlign: "left",
            fontWeight: "lighter",
          }}
        >
          {props.subtitle}
        </div>
      </div>
    </animated.div>
  );
}

export function Hero(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        height: "50vh",
        width: "100vw",
        paddingTop: "75px",
        background: props.background,
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={10}>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <Up delay={timelineDelays[0]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "36px",
                    color: themeStyle.primaryColor,
                    fontWeight: "bold",
                    paddingLeft: "15px",
                    paddingTop: state.auth.isMobile ? "50px" : 0,
                  }}
                >
                  {props.title}
                </div>
              </Up>
              <Up delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: themeStyle.primaryColor,
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle}
                </div>
              </Up>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "80%",
                width: "80%",
                textAlign: "left",
              }}
            >
              <Up delay={timelineDelays[2]}>
                <img style={{ width: "80%" }} src={props.image}></img>
              </Up>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function ContentSideBySide(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        minHeight: "60vh",
        width: "100vw",
        paddingTop: "75px",
        background: "white",
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={10}>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <RightInView delay={timelineDelays[0]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "60px",
                    paddingLeft: "15px",
                  }}
                >
                  {props.title}
                </div>
              </RightInView>
              <div
                style={{
                  margin: "15px 0px 50px 0px",
                  width: "60px",
                  height: "5px",
                  backgroundColor: "#1868DD",
                  marginLeft: "15px",
                }}
              ></div>
              <RightInView delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "22px",
                    color: "grey",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle}
                </div>
              </RightInView>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
              }}
            >
              <RightInView delay={timelineDelays[2]}>
                <img
                  style={{
                    width: "100%",
                    height: "550px",
                    objectFit: "contain",
                  }}
                  src={props.image}
                ></img>
              </RightInView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function ContentSideBySideCustomInv(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        paddingTop: "75px",
        background: props.background,
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid
            container
            spacing={10}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
              }}
            >
              <RightInView delay={timelineDelays[2]}>{props.image}</RightInView>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <RightInView delay={timelineDelays[0]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize:
                      props.titleFontSize == null
                        ? "60px"
                        : props.titleFontSize,
                    color:
                      props.titleColor == null ? "black" : props.titleColor,
                    fontWeight: "bold",
                    lineHeight: "60px",
                    paddingLeft: "15px",
                  }}
                >
                  {props.title}
                </div>
              </RightInView>
              <div
                style={{
                  margin: "15px 0px 50px 0px",
                  width: "60px",
                  height: "5px",
                  backgroundColor: "#1868DD",
                  marginLeft: "15px",
                }}
              ></div>
              <RightInView delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize:
                      props.subtitleFontSize == null
                        ? "20px"
                        : props.subtitleFontSize,
                    color: "black",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle}
                </div>
              </RightInView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function ContentSideBySideCustom(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        paddingTop: "75px",
        background: props.background,
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid
            container
            spacing={5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "80%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <RightInView delay={timelineDelays[0]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize:
                      props.titleFontSize == null
                        ? "60px"
                        : props.titleFontSize,
                    color:
                      props.titleColor == null ? "black" : props.titleColor,
                    fontWeight: "bold",
                    lineHeight: "60px",
                    paddingLeft: "15px",
                  }}
                >
                  {props.title}
                </div>
              </RightInView>
              <div
                style={{
                  margin: "15px 0px 50px 15px",
                  width: "60px",
                  height: "5px",
                  backgroundColor: "#1868DD",
                  paddingLeft: "15px",
                }}
              ></div>
              <RightInView delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize:
                      props.subtitleFontSize == null
                        ? "20px"
                        : props.subtitleFontSize,
                    color: "black",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle}
                </div>
              </RightInView>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "80%",
                textAlign: "left",
              }}
            >
              <RightInView delay={timelineDelays[2]}>{props.image}</RightInView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function ContentSideBySideInv(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        minHeight: "60vh",
        width: "100vw",
        paddingTop: "75px",
        background: "white",
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={5}>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <RightInView delay={timelineDelays[0]}>
                <img
                  style={{
                    width: "100%",
                    height: "550px",
                    objectFit: "contain",
                  }}
                  src={props.image}
                ></img>
              </RightInView>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
              }}
            >
              <RightInView delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "60px",
                    paddingLeft: "15px",
                  }}
                >
                  {props.title}
                </div>
              </RightInView>
              <div
                style={{
                  margin: "15px 0px 50px 0px",
                  width: "60px",
                  height: "5px",
                  backgroundColor: "#1868DD",
                  marginLeft: "15px",
                }}
              ></div>
              <RightInView delay={timelineDelays[1]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "22px",
                    color: "grey",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle}
                </div>
              </RightInView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function ThreeSideBySide(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        width: "100vw",
        background: "white",
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={props.spacing == null ? 10 : props.spacing}>
            <Grid
              item
              md={4}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <UpInView delay={timelineDelays[0]}>
                <img
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    maxWidth: "500px",
                    objectFit: "cover",
                  }}
                  src={props.image1}
                ></img>
              </UpInView>

              <UpInView delay={timelineDelays[1]}>
                <div
                  style={{
                    marginTop: "50px",
                    textAlign: "center",
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "40px",
                  }}
                >
                  {props.title1}
                </div>
              </UpInView>
              <UpInView delay={timelineDelays[2]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "20px",
                    color: "grey",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle1}
                </div>
              </UpInView>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <UpInView delay={timelineDelays[0]}>
                <img
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    maxWidth: "500px",
                    objectFit: "cover",
                  }}
                  src={props.image2}
                ></img>
              </UpInView>

              <UpInView delay={timelineDelays[1]}>
                <div
                  style={{
                    marginTop: "50px",
                    textAlign: "center",
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "40px",
                  }}
                >
                  {props.title2}
                </div>
              </UpInView>
              <UpInView delay={timelineDelays[2]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "20px",
                    color: "grey",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle2}
                </div>
              </UpInView>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sm={12}
              style={{
                height: "100%",
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "start",
                minHeight: "50vh",
              }}
            >
              <UpInView delay={timelineDelays[0]}>
                <img
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    maxWidth: "500px",
                    objectFit: "cover",
                  }}
                  src={props.image2}
                ></img>
              </UpInView>

              <UpInView delay={timelineDelays[1]}>
                <div
                  style={{
                    marginTop: "50px",
                    textAlign: "center",
                    borderRadius: "25px",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                    lineHeight: "40px",
                  }}
                >
                  {props.title3}
                </div>
              </UpInView>
              <UpInView delay={timelineDelays[2]}>
                <div
                  style={{
                    borderRadius: "25px",
                    fontSize: "20px",
                    color: "grey",
                    paddingLeft: "15px",
                  }}
                >
                  {props.subtitle3}
                </div>
              </UpInView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

export function Gallery(props) {
  const state = useSelector((state) => state);
  const timelineDelays = [0, 300, 600];

  return (
    <div
      style={{
        width: "100vw",
        paddingTop: "75px",
        background: "white",
      }}
    >
      <Grid container>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={5}>
            {props.gallery1.map((gal1, index) => (
              <Grid
                item
                md={4}
                xs={12}
                sm={12}
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "start",
                  minHeight: "50vh",
                }}
              >
                <UpInView delay={timelineDelays[index]}>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      alt="Norway"
                      style={{
                        height: "50vh",
                        width: "100%",
                        backgroundSize: "cover",
                        background:
                          "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                          gal1["image"] +
                          "')",
                      }}
                    />
                    <div
                      style={{
                        borderTopRightRadius: "50px",
                        padding: "20px",
                        position: "absolute",
                        textAlign: "left",
                        bottom: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: "25px",
                          color: "white",
                          fontWeight: 600,
                        }}
                      >
                        {gal1["title"]}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "lighter",
                        }}
                      >
                        {gal1["subtitle"]}
                      </div>
                    </div>
                  </div>
                </UpInView>
              </Grid>
            ))}
            {props.gallery2.map((gal2, index) => (
              <Grid
                item
                md={4}
                xs={12}
                sm={12}
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "start",
                  minHeight: "50vh",
                }}
              >
                <UpInView delay={timelineDelays[index]}>
                  <div
                    onClick={() => {}}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      alt="Norway"
                      style={{
                        height: "50vh",
                        width: "100%",
                        backgroundSize: "cover",
                        background:
                          "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                          gal2["image"] +
                          "')",
                      }}
                    />
                    <div
                      style={{
                        borderTopRightRadius: "50px",
                        padding: "20px",
                        position: "absolute",
                        textAlign: "left",
                        bottom: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: "25px",
                          color: "white",
                          fontWeight: 600,
                        }}
                      >
                        {gal2["title"]}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "lighter",
                        }}
                      >
                        {gal2["subtitle"]}
                      </div>
                    </div>
                  </div>
                </UpInView>
              </Grid>
            ))}
            {props.gallery3.map((gal3, index) => (
              <Grid
                item
                md={4}
                xs={12}
                sm={12}
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "start",
                  minHeight: "50vh",
                }}
              >
                <UpInView delay={timelineDelays[index]}>
                  <div
                    onClick={() => {}}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      alt="Norway"
                      style={{
                        height: "50vh",
                        width: "100%",
                        backgroundSize: "cover",
                        background:
                          "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                          gal3["image"] +
                          "')",
                      }}
                    />
                    <div
                      style={{
                        borderTopRightRadius: "50px",
                        padding: "20px",
                        position: "absolute",
                        textAlign: "left",
                        bottom: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: "25px",
                          color: "white",
                          fontWeight: 600,
                        }}
                      >
                        {gal3["title"]}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "lighter",
                        }}
                      >
                        {gal3["subtitle"]}
                      </div>
                    </div>
                  </div>
                </UpInView>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}
