import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import { Grid } from "@mui/material";

export default function Privacy() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="Privacy Policy"
          subtitle="We take your privacy very seriously"
          image={require("../img/about-banner.png")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Grid
          container
          style={{
            backgroundColor: "white",
          }}
        >
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div
              style={{
                padding: "50px",
                fontSize: "26px",
                textAlign: "justify",
                backgroundColor: "white",
              }}
            >
              <b> We built the app as an Ad Supported app.</b>
              <br /> <br />
              This page is used to inform visitors regarding policies with the
              collection, use, and disclosure of Personal Information if anyone
              decided to use the Service. If you choose to use appointee
              Service, then you agree to the collection and use of information
              in relation to this policy. The Personal Information that we
              collect is used for providing and improving the Service. we will
              not use or share your information with anyone except as described
              in this Privacy Policy. The terms used in this Privacy Policy have
              the same meanings as in our Terms and Conditions, which is
              accessible at unless otherwise defined in this Privacy Policy.
              <br /> <br /> <b>Information Collection and Use</b>
              <br />
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to Camera, Internet,
              Read-Write External Storage, Android Advertising ID(Notification).
              The information that we request will be retained on your device
              and is not collected by any way. We collect information from you
              when you download Apps. Everything is taken on Your prior
              permission. The app does use third party services that may collect
              information used to identify you. Link to privacy policy of third
              party service providers used by the app Google Play Services AdMob
              <br /> <br /> <b>Third-party disclosure</b> <br />
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it’s release is appropriate to comply
              with the law, enforce our site policies, or protect ours or
              others’ rights, property or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.
              <br /> <br /> <b>Cookies</b> <br />
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device’s internal memory. This Service does not use these
              “cookies” explicitly. However, the app may use third party code
              and libraries that use “cookies” to collect information and
              improve their services. You have the option to either accept or
              refuse these cookies and know when a cookie is being sent to your
              device. If you choose to refuse our cookies, you may not be able
              to use some portions of this Service.
              <br /> <br /> <b>Log Data</b> <br />
              we want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information on your
              phone called Log Data. This Log Data may include information such
              as your device Internet Protocol (“IP”) address, device name,
              operating system version, the configuration of the app when
              utilizing my Service, the time and date of your use of the
              Service, and other statistics.
              <br /> <br /> <b>Service Providers</b> <br />
              we may employ third-party companies and individuals due to the
              following reasons: To facilitate our Service; To provide the
              Service on our behalf; To perform Service-related services; or To
              assist us in analyzing how our Service is used. we want to inform
              users of this Service that these third parties have access to your
              Personal Information. The reason is to perform the tasks assigned
              to them on our behalf. However, they are obligated not to disclose
              or use the information for any other purpose.
              <br /> <br /> <b>Security</b> <br />
              we value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security. Links to
              Other Sites This Service may contain links to other sites. If you
              click on a third-party link, you will be directed to that site.
              Note that these external sites are not operated by me. Therefore,
              we strongly advise you to review the Privacy Policy of these
              websites. we have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
              <br /> <br /> <b>Children’s Privacy</b> <br />
              These Services do not address anyone under the age of 13. we do
              not knowingly collect personally identifiable information from
              children under 13. In the case we will dicover that a child under
              13 has provided me with personal information, we immediately
              delete this from our servers. If you are a parent or guardian and
              you are aware that your child has provided us with personal
              information, please contact me so that we will be able to do
              necessary actions.
              <br /> <br /> <b>Changes to This Privacy Policy</b> <br />
              we may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. we will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page. Contact Us If you have any questions or
              suggestions about our Privacy Policy, do not hesitate to contact
              us. on our Whatsapp number on 8484906268
            </div>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        </Grid>

        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />

        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
