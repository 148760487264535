import React, { Fragment, useState, useEffect, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import { duration, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SchoolIcon from "@mui/icons-material/School";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import { themeStyle, themeStyleDark } from "./Components";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import Select from "@mui/material/Select";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ScrollTop from "./ScrollTop";
import Snackbar from "@mui/material/Snackbar";
import { Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSpring, useSprings, useScroll, animated } from "@react-spring/web";
import { CardHorizontal, SpacerH, SpacerV } from "./Hero";
import InterestsIcon from "@mui/icons-material/Interests";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AppsIcon from "@mui/icons-material/Apps";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SecurityIcon from "@mui/icons-material/Security";
import TaskIcon from "@mui/icons-material/Task";
const drawerWidth = 310;

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);
  const [scrollHidden, setScrollHidden] = useState(false);

  const [menuContainer, menuContainerApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const [navContainer, navContainerApi] = useSpring(() => ({
    from: { transform: "translate(0px, -200px)", opacity: 0 },
    to: { transform: "translate(0px, 0px)", opacity: 1 },
    delay: props.delay,
    config: { duration: 400 },
  }));

  const menuUnderlines = useSprings(
    7,
    [0, 1, 2, 3, 4, 5, 6].map((prop, index) => ({
      from: { width: "0%" },
      to: { width: activeMenu == index ? "100%" : "0%" },
    }))
  );

  // const menuUnderlinesMouseEnter = () => {
  //   menuUnderlinesApi.start({
  //     from: { width: "0%" },
  //     to: { width: "100%" },
  //   });
  // };

  // const menuUnderlinesMouseLeave = () => {
  //   menuUnderlinesApi.start({
  //     from: { width: "100%" },
  //     to: { width: "0%" },
  //   });
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const [about, setAbout] = useState("");
  const [facility, setFacility] = useState("");
  const [admission, setAdmission] = useState("");
  const [studentLife, setStudentLife] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      let direction = lastScrollY < window.scrollY ? "down" : "up";
      lastScrollY = window.scrollY;

      if (!scrollHidden) {
        if (window.scrollY > 500 && direction == "down") {
          navContainerApi.start({
            from: { transform: "translate(0px, 0px)", opacity: 0 },
            to: { transform: "translate(0px, -200px)", opacity: 1 },
            delay: props.delay,
            config: { duration: 400 },
          });
          setScrollHidden(true);
        }
      }
      if (scrollHidden) {
        if (window.scrollY > 500 && direction == "up") {
          navContainerApi.start({
            from: { transform: "translate(0px, -200px)", opacity: 0 },
            to: { transform: "translate(0px, 0px)", opacity: 1 },
            delay: props.delay,
            config: { duration: 400 },
          });
          setScrollHidden(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollHidden]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ScrollTop />
      <Snackbar
        open={state.auth.alert != null ? true : false}
        autoHideDuration={6000}
        onClose={() => dispatch({ type: "REMOVE_ALERT" })}
        message={state.auth.alert == null ? "" : state.auth.alert.message}
        action={
          <div
            onClick={() => dispatch({ type: "REMOVE_ALERT" })}
            style={{ padding: "2px", cursor: "pointer" }}
          >
            <CloseIcon />
          </div>
        }
      />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <animated.div
          id="nav-bar"
          className="navbar"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            padding: "10px 25px 10px 25px",
            width: "100%",
            backgroundColor: "#03A9F4",
            borderBottom: "1px solid #666",
            ...navContainer,
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
              display: "flex",
              alignItems: "center",
            }}
            to={"/"}
          >
            <img src={require("../img/logo.png")} style={{ height: "80px" }} />
            <div
              style={{
                marginLeft: "10px",
                color: "white",
                fontWeight: "bold",
                fontSize: "30px",
                textAlign: "left",
              }}
            >
              Appointee
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "lighter",
                  marginTop: "-10px",
                }}
              >
                {" "}
                Your appointment companion
              </div>
            </div>
          </Link>
          <div>
            {state.auth.isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <div
                      className="nav-item"
                      onMouseEnter={() => {
                        setActiveMenu(0);
                        menuContainerApi.start({
                          from: { opacity: 1, display: "block" },
                          to: { opacity: 0, display: "none" },
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveMenu(10);
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                      <HomeIcon
                        className="nav-icon"
                        style={{
                          fontSize: 25,
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                    <animated.div
                      key={0}
                      className="nav-underline"
                      style={menuUnderlines[0]}
                    />
                  </div>

                  <div>
                    <div
                      className="nav-item"
                      onMouseEnter={() => {
                        setActiveMenu(1);
                        menuContainerApi.start({
                          from: { opacity: 1, display: "block" },
                          to: { opacity: 0, display: "none" },
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveMenu(10);
                      }}
                      onClick={() => {
                        navigate("/careers");
                      }}
                    >
                      Careers
                      <AdsClickIcon
                        className="nav-icon"
                        style={{
                          fontSize: 25,
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                    <animated.div
                      key={1}
                      className="nav-underline"
                      style={menuUnderlines[1]}
                    />
                  </div>
                  <div>
                    <div
                      className="nav-item"
                      onMouseEnter={() => {
                        setActiveMenu(2);
                        menuContainerApi.start({
                          from: { opacity: 1, display: "block" },
                          to: { opacity: 0, display: "none" },
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveMenu(10);
                      }}
                      onClick={() => {
                        navigate("/about");
                      }}
                    >
                      About
                      <InterestsIcon
                        className="nav-icon"
                        style={{
                          fontSize: 25,
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                    <animated.div
                      key={1}
                      className="nav-underline"
                      style={menuUnderlines[2]}
                    />
                  </div>

                  <div>
                    <div
                      className="nav-item"
                      onMouseEnter={() => {
                        setActiveMenu(3);
                        menuContainerApi.start({
                          from: { opacity: 1, display: "block" },
                          to: { opacity: 0, display: "none" },
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveMenu(10);
                      }}
                      onClick={() => {
                        navigate("/contact");
                      }}
                    >
                      Contact us
                      <PhoneInTalkIcon
                        className="nav-icon"
                        style={{
                          fontSize: 25,
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                    <animated.div
                      key={1}
                      className="nav-underline"
                      style={menuUnderlines[3]}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className="nav-item"
                    onMouseEnter={() => {
                      setActiveMenu(4);
                      menuContainerApi.start({
                        from: { opacity: 1, display: "block" },
                        to: { opacity: 0, display: "none" },
                      });
                    }}
                    onMouseLeave={() => {
                      setActiveMenu(10);
                    }}
                    onClick={() => {
                      navigate("/marketing");
                    }}
                  >
                    Pricing
                    <WorkspacesIcon
                      className="nav-icon"
                      style={{
                        fontSize: 25,
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                  <animated.div
                    key={1}
                    className="nav-underline"
                    style={menuUnderlines[4]}
                  />
                </div>
                <div>
                  <div
                    className="nav-item"
                    onMouseEnter={() => {
                      setActiveMenu(5);
                      menuContainerApi.start({
                        from: { opacity: 1, display: "block" },
                        to: { opacity: 0, display: "none" },
                      });
                    }}
                    onMouseLeave={() => {
                      setActiveMenu(10);
                    }}
                    onClick={() => {
                      navigate("/privacy");
                    }}
                  >
                    Privacy
                    <SecurityIcon
                      className="nav-icon"
                      style={{
                        fontSize: 25,
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                  <animated.div
                    key={1}
                    className="nav-underline"
                    style={menuUnderlines[5]}
                  />
                </div>
                <div>
                  <div
                    className="nav-item"
                    onMouseEnter={() => {
                      setActiveMenu(6);
                      menuContainerApi.start({
                        from: { opacity: 1, display: "block" },
                        to: { opacity: 0, display: "none" },
                      });
                    }}
                    onMouseLeave={() => {
                      setActiveMenu(10);
                    }}
                    onClick={() => {
                      navigate("/terms");
                    }}
                  >
                    Terms
                    <TaskIcon
                      className="nav-icon"
                      style={{
                        fontSize: 25,
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                  <animated.div
                    key={1}
                    className="nav-underline"
                    style={menuUnderlines[6]}
                  />
                </div>
                <div>
                  <div className="nav-item">
                    <LanguageIcon
                      className="nav-icon"
                      style={{
                        fontSize: 25,
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="nav-item">
                    <MenuOpenIcon
                      className="nav-icon"
                      style={{
                        fontSize: 25,
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <animated.div
            onMouseLeave={() => {
              menuContainerApi.start({
                from: { opacity: 1, display: "block" },
                to: { opacity: 0 },
              });
              setTimeout(
                () =>
                  menuContainerApi.start({
                    from: { opacity: 0, display: "block" },
                    to: { opacity: 0, display: "none" },
                  }),
                500
              );
            }}
            style={{ ...menuContainer }}
            id="menu-container"
            className="menu-open-container"
          >
            <div
              style={{
                textAlign: "left",
                marginLeft: "15px",
                borderRadius: "25px",
                fontSize: "20px",
                color: themeStyle.primaryColor,
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Web design
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <CardHorizontal
                navigate={"webdesign"}
                title={"Websites"}
                subtitle={"Innovate with Intelligence"}
                content={
                  <AppsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"webdesign"}
                title={"Progressive Web Apps (PWA)"}
                subtitle={"Securing tomorrow's transactions"}
                content={
                  <HomeIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"webdesign"}
                title={"Web Technologies"}
                subtitle={"Unleash cloud potential"}
                content={
                  <AdsClickIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginLeft: "15px",
                borderRadius: "25px",
                fontSize: "20px",
                color: themeStyle.primaryColor,
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Mobile App design
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <CardHorizontal
                navigate={"mobileapp"}
                title={"Cross Platform Hybrid Apps"}
                subtitle={"Future proof your business"}
                content={
                  <MenuOpenIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"mobileapp"}
                title={"Playstore/Appstore Deployment"}
                subtitle={"Innovate with Intelligence"}
                content={
                  <AppsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"mobileapp"}
                title={"Integration Testing"}
                subtitle={"Continuous Integration"}
                content={
                  <InterestsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginLeft: "15px",
                borderRadius: "25px",
                fontSize: "20px",
                color: themeStyle.primaryColor,
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              AI and ML
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <CardHorizontal
                navigate={"ai_ml"}
                title={"Artificial Intelligence"}
                subtitle={"Unleash cloud potential"}
                content={
                  <AdsClickIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"ai_ml"}
                title={"Blockchain"}
                subtitle={"Unleash cloud potential"}
                content={
                  <AdsClickIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"ai_ml"}
                title={"Machine Learning"}
                subtitle={"Protecting the Digital frontier"}
                content={
                  <AutoAwesomeIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginLeft: "15px",
                borderRadius: "25px",
                fontSize: "20px",
                color: themeStyle.primaryColor,
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Software Development
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <CardHorizontal
                navigate={"software"}
                title={"Digital transformation"}
                subtitle={"Future proof your business"}
                content={
                  <MenuOpenIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"software"}
                title={"Cybersecurity"}
                subtitle={"Innovate with Intelligence"}
                content={
                  <AppsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"software"}
                title={"Devops"}
                subtitle={"Continuous Integration"}
                content={
                  <InterestsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginLeft: "15px",
                borderRadius: "25px",
                fontSize: "20px",
                color: themeStyle.primaryColor,
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              UI/UX Design
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <CardHorizontal
                navigate={"uiux"}
                title={"Wireframing"}
                subtitle={"Future proof your business"}
                content={
                  <MenuOpenIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"uiux"}
                title={"WebFX"}
                subtitle={"Innovate with Intelligence"}
                content={
                  <AppsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
              <SpacerH width="50px" />
              <CardHorizontal
                navigate={"uiux"}
                title={"3D Designs"}
                subtitle={"Continuous Integration"}
                content={
                  <InterestsIcon
                    style={{ fontSize: "30px", color: themeStyle.primaryColor }}
                  />
                }
              />
            </div>
          </animated.div>
        </animated.div>
      </AppBar>

      {state.auth.isMobile ? (
        <Drawer variant="temporary" anchor="left" open={open} style={{}}>
          <DrawerHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link
              style={{
                marginTop: "15px",
                textDecoration: "none",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryButtonTextColor
                    : themeStyle.primaryButtonTextColor,
              }}
              to={"/"}
            >
              <img
                src={require("../img/logo.png")}
                style={{ height: "80px" }}
              />
              <div style={{ color: "black" }}>
                Appointee
                <br />
                <span> Your appointment companion</span>
              </div>
            </Link>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
          <List>
            <Divider />

            <div
              onClick={() => {
                navigate("/about");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              About
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/contact");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              Contact Us
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/careers");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              Careers
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/webdesign");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              Web Design
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/mobileapp");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              Mobile Apps
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/ai_ml");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              AI and ML
            </div>
            <Divider />
            <div
              onClick={() => {
                navigate("/software");
              }}
              style={{
                width: "100%",
                fontSize: 25,
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              Software Development
            </div>
            <Divider />
          </List>
        </Drawer>
      ) : (
        <Fragment></Fragment>
      )}
    </Box>
  );
}

export default Sidebar;
