export const themeStyleDark = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#113559",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#113559",
  primaryButtonTextColor: "white",
  secondaryButtonColor: "#000080",
  backgroundGradient: "linear-gradient(135deg, #E4F9FF, #03A9F4)",
};

export const themeStyle = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#113559",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#113559",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "#000080",
  backgroundGradient: "linear-gradient(135deg, #E4F9FF, #03A9F4)",
};
