import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
  ContentSideBySide,
  ContentSideBySideInv,
  ThreeSideBySide,
  Hero,
  Gallery,
  SpacerV,
} from "../components/Hero";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WindowIcon from "@mui/icons-material/Window";
import TabIcon from "@mui/icons-material/Tab";
import DvrIcon from "@mui/icons-material/Dvr";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function Software() {
  useEffect(() => {}, []);

  const state = useSelector((state) => state);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <Hero
          background={themeStyle.backgroundGradient}
          title="UI/UX Design"
          subtitle="Ultimate UX Design and Front-End App Engineering"
          image={require("../img/about-banner.png")}
        />

        <ContentSideBySideInv
          title="Key benefits of Mobile app Solutions for Business"
          subtitle={
            <div>
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "orange",
                    marginRight: "15px",
                  }}
                />
                Better Decision-Making
              </div>
              Artificial Intelligence (AI) and Machine Learning (ML) speed up
              data processing and programming, which reduces time and effort in
              making better decisions.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "blue",
                    marginRight: "15px",
                  }}
                />
                Accuracy{" "}
              </div>
              Prevent human errors and ensure accurate results with the help of
              Artificial Intelligence in your processes.
              <br />
              <br />
              <div
                style={{ display: "flex", color: "black", fontWeight: "bold" }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "30px",
                    color: "teal",
                    marginRight: "15px",
                  }}
                />
                Process Optimization
              </div>
              Machine learning can be used to streamline corporate processes and
              help find anomalies in them to boost efficiency.
            </div>
          }
          image={require("../img/uiu.jpg")}
        />
        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <ThreeSideBySide
          spacing={5}
          subtitle1={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <TravelExploreIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Research{" "}
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                In the Research phase, our approach is driven by data that
                echoes the user’s voice while aligning perfectly with your
                business objectives. This helps you to set the design patterns
                intuitively and clearly.
              </div>
            </div>
          }
          subtitle2={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <DvrIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Design{" "}
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                During the designing phase, our team of UX-UI experts dedicates
                itself to creating designs that are not only loved by users for
                their intuitiveness but are also scalable to meet growing
                demands.
              </div>
            </div>
          }
          subtitle3={
            <div
              style={{
                minHeight: "36vh",
                padding: "20px",
                paddingTop: "15px",
                borderRadius: "10px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <WindowIcon
                style={{
                  fontSize: "100px",
                  color: themeStyle.primaryColor,
                  marginRight: "15px",
                }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Build{" "}
                </div>
                <div
                  style={{
                    margin: "15px 0px 35px 0px",
                    width: "60px",
                    height: "3px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                ></div>
                In this phase, we transform designs into pixel-perfect,
                adaptable, and scalable products. Whether it’s a startup idea or
                an enterprise project, our technical prowess ensures that your
                vision is translated into reality.
              </div>
            </div>
          }
        />

        <SpacerV height={"100px"} />
        <SpacerV height={"100px"} />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
